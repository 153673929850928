#Dashboard {
	display: flex;
	flex-wrap: wrap;
}

#mobile-header {
	display: flex;
	justify-content: flex-start;
	/* adjustment */
	position: sticky;
	top: 0;
	background-color: var(--color-primary);
	border-bottom: 3px solid var(--color-primary-darker);
	z-index: 97;
}

#mobile-header .logo {
	color: white;
	flex-grow: 4;
	text-align: center;
	justify-content: center;
	display: flex;
	align-items: center;
	font-size: 1.2em;
}

#mobile-header .logo img {
	height: 3rem;
}

#mobile-header .left,
#mobile-header .right {
	display: flex;
	align-items: center;
}

#mobile-header .spanButton {
	font-size: 1.5rem;
	padding: 1rem;
	color: var(--color-primary-c);
}
#mobile-header .spanButton:hover {
	background-color: var(--color-primary-c);
	color: var(--color-primary);
}

#main-sidebar-wrap {
	background-color: var(--color-primary);
	border-right: 3px solid var(--color-primary-darker);
	flex-grow: 0;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
}

#main-sidebar-wrap.mobile {
	position: fixed;
	top: 0;
	left: 0;
	max-width: 90%;
	z-index: 99;
}

#main-sidebar-wrap.hidden {
	max-width: 0;
}

#close-sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.536);
	z-index: 98;
}

#page-wrap {
	flex-basis: 0;
	flex-grow: 999;
	overflow-y: scroll;
	height: 100vh;
}

#MainSidebar {
	padding: 1em;
	width: 200px;
}

#MainSidebarMenu ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
}

.MainSidebarMenuItem {
	flex-grow: 1;
}

.MainSidebarMenuItem a {
	display: block;
	padding: 1em;
	background-color: rgba(0, 0, 0, 0.15);
	margin-bottom: 0.2em;
	text-decoration: none;
	color: white;
	border-radius: 3px;
	text-transform: uppercase;
}

.MainSidebarMenuItem a:hover {
	background-color: rgba(255, 255, 255, 0.95);
	color: var(--color-primary-darker);
}

.MainSidebarMenuItem span {
	margin-left: 10px;
}

#main-logo {
	margin: 10px auto;
	background: white;
	border-radius: 3px;
	overflow: hidden;
	height: 3em;
	padding: 10px;
}

#MainSidebar #main-logo img {
	background-color: white;
	padding: 5px;
	height: calc(100% - 10px);
}

#MainSidebar .user-greeting {
	color: white;
}

#app-name {
	font-size: 1.5em;
	color: white;
	text-align: center;
	font-weight: 900;
}

#page-wrap,
#main-sidebar-wrap,
#html {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#page-wrap::-webkit-scrollbar,
#main-sidebar-wrap::-webkit-scrollbar,
#html::-webkit-scrollbar {
	width: 0; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}

#PageTitle {
	width: 100%;
	padding: 0.8rem;
	box-sizing: border-box;
	background-color: var(--color-secondary);
}

#PageTitle h1 {
	margin: 0;
	padding: 0.3em;
	font-size: larger;
	color: var(--color-secondary-c);
}

#PageContent {
	padding: 0.5em;
	padding-bottom: 400px;
}

.home-layout {
	display: flex;
	flex-direction: row;
	flex: 1 1 0;
	justify-content: space-around;
	gap: 0.5rem;
}
.hl-left {
	flex-basis: 66.666%;
}
.hl-right {
	flex-basis: 33.333%;
}

@media only screen and (max-width: 600px) {
	.home-layout {
		flex-direction: column;
	}
}
