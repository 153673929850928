.Collapsible {
	border: 1px solid var(--color-border);
	margin-bottom: 30px;
	border-radius: 3px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.Collapsible > .content {
	max-height: 0px;
	overflow: hidden;
	-webkit-transition: max-height 1s ease;
	-moz-transition: max-height 1s ease;
	-o-transition: max-height 1s ease;
	transition: max-height 1s ease;
}
.Collapsible > .content.open {
	max-height: 1200px;
}
.Collapsible .content-inner {
	padding: 5px;
	background-color: var(--color-collapsible-background);
}

.Collapsible .title {
	background-color: var(--color-collapsible-header);
	color: var(--color-collapsible-header-c);
	padding: 10px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--color-border);
	align-items: center;
	font-weight: 400;
}

.collapsible-column-view {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}
.collapsible-column-view .Collapsible {
	flex-grow: 1;
    flex-basis: 0;
}

@media only screen and (max-width: 800px) {
	.collapsible-column-view {
		flex-direction: column;
		gap: 0;
	}
}
