.LoadingMessage {
	position: relative;
}

.LoadingMessage span {
	display: block;
	margin-top: 5px;
	font-weight: bold;
	color: var(--color-primary-darker);
}

.LoadingMessage .dot {
	animation: expand 0.75s ease-in-out infinite;
	border-radius: 20px;
	display: inline-block;
	transform-origin: center center;
	margin: 0 3px;
	width: 10px;
	height: 10px;
	background: var(--color-primary-darker);
}

.LoadingMessage .dot:nth-child(2) {
	animation-delay: 180ms;
}

.LoadingMessage .dot:nth-child(3) {
	animation-delay: 360ms;
}

.LoadingMessage .dot:nth-child(4) {
	animation-delay: 540ms;
}

@keyframes expand {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.55);
	}
}
