.Clocking {
    width: 300px;
    max-width: 80%;
    background-color: #eee;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin: 0 auto;
}
.Clocking button {
    display: block;
    width: 80%;
    margin: 5px auto;
}
.Clocking input {
    display: block;
    width: 80%;
    margin: 5px auto;
    padding: 10px;
}
.Clocking p.time {
    font-weight: bold;
    color: var(--color-action-secondary);
}
.Clocking .clocking-title {
    font-weight: bold;
}

.status-button {
    background-color: black;
    color: white;
    padding: 4px 10px;
    border-radius: 10px;
    font-weight: bold;
}
.status-button.yellow {
    background-color: rgb(225, 165, 0);
}
.status-button.red {
    background-color: rgb(195, 0, 0);
}
.status-button.green {
    background-color: rgb(0, 176, 21);
}
.status-button.grey {
    background-color: rgb(97, 97, 97);
}
