.ButtonBar {
	padding: 0.5em 0;
}
.ButtonBar .Button:not(:first-child) {
	margin-left: 0.5em;
}
.Button {
	display: inline-block;
	font-size: 14px;
	padding: 7px 19px;
	border-radius: 5px;
	border: 1px solid var(--color-button-border);
	background-color: var(--color-action-secondary);
	color: #ffffff;
	text-decoration: none;
}

.Button:hover {
	-webkit-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
	-moz-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
	box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
}
