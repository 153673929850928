#Boms {
	border-radius: 3px;
}

/* Popup */
#NewBomForm {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	overflow: auto;
	border: none;
	z-index: 9999;
}
#NewBomForm .content {
	position: relative;
	width: 600px;
	max-width: 80%;
	margin: 0 auto;
	background-color: #fff;
	height: 80%;
	padding: 20px;
	border-radius: 3px;
	overflow-y: scroll;
}

.inputGroup {
	display: flex;
	background-color: #e7e7e7;
	border-radius: 3px;
}
.inputGroup:nth-of-type(2n) {
	background-color: #f4f4f4;
}
.inputGroup > div {
	margin: 5px;
}

.inputGroup .ig-mtl {
	align-content: stretch;
	flex-grow: 1;
}

.inputGroup div.ig-qty {
	width: 100px;
	display: flex;
}
.inputGroup div.ig-del {
	display: flex;
}

.inputGroup input[type="number"] {
	font-size: 1em;
	max-width: 100%;
	border-radius: 4px;
	border: 1px solid hsl(0, 0%, 80%);
	text-align: center;
}
