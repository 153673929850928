.ErrorMessage {
	padding: 0.8rem;
	background-color: rgb(255, 208, 208);
	border-left: 5px solid rgb(255, 47, 47);
}
.ErrorMessage .err-prefix {
	color: rgb(255, 47, 47);
	font-weight: bold;
	margin-right: 1rem;
}
.ErrorMessage .err-message {
	font-size: 0.8rem;
}
