#InventoryEditor {
	display: flex;
	flex-wrap: wrap;
	min-width: 0;
}
.InventoryLine {
	margin: 5px;
	flex: 1 1 auto;
	min-width: 0;
}

.inv-qty {
	background-color: var(--color-primary-c);
	padding: 5px;
	border: 1px solid var(--color-border);
	font-weight: 900;
}

button:disabled,
input[type="submit"]:disabled,
button[disabled] {
	background-color: var(--color-action-disabled);
	color: var(--color-action-disabled-c);
}

.inv-input-group {
	display: flex;
	height: 40px;
	min-width: 0;
}

.inv-input-group button {
	background-color: #fff;
	color: black;
	border-radius: 0;
	border: 1px solid var(--color-border);
	border-right: 0;
	border-left: 0;
	padding: 10px;
}
.inv-input-group button:first-child {
	border-left: 1px solid var(--color-border);
}
.inv-input-group button:hover {
	box-shadow: none;
}
.inv-input-group button:first-child {
	background-color: #fff;
	border-radius: 10px 0 0 10px;
}
.inv-input-group button.selected {
	background-color: var(--color-action-secondary);
	color: var(--color-action-secondary-c);
}
.inv-input-group button:hover {
	background-color: var(--color-action-secondary-lighter);
	color: var(--color-action-secondary-c);
}
.inv-input-group input[type="number"] {
	border: 1px solid var(--color-border);
	padding: 10px;
	flex: 1 1 0;
	border-radius: 0;
	min-width: 0;
}
.inv-input-group .step-buttons {
	display: flex;
	flex-direction: column;
	height: 40px;
}
.inv-input-group .step-buttons button {
	background-color: var(--color-border-lighter);
	border-radius: 0 10px 0 0;
	display: flex;
	flex: 1 1 0%;
	padding: 0px 8px;
	border: 1px solid var(--color-border);
	border-left: 0;
}
.inv-input-group .step-buttons button:hover {
	background-color: #b5b5b5;
}
.inv-input-group .step-buttons button:first-child {
	border-radius: 0 10px 0 0;
	border-bottom: 0;
}
.inv-input-group .step-buttons button:last-child {
	border-radius: 0 0 10px 0;
}
.button span {
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	gap: 8px;
}

.inv-change .inv-s {
	color: #b26b00;
}

.inv-change .inv-d.inv-plus {
	color: rgb(0, 120, 0);
}
.inv-change .inv-d.inv-minus {
	color: rgb(155, 0, 0);
}

.inv-change .inv-r {
	font-weight: bold;
}

.InventoryCreatorSelector {
	margin: 5px;
	padding: 5px;
	border: 1px solid var(--color-border);
	border-radius: 5px;
}
.InventoryCreatorSelector span, .InventoryCreatorSelector button {
	margin: 5px;
}
