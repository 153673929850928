.NumberKeypad {
	margin: 0 auto;
	text-align: center;
	max-width: 90%;
	width: 400px;
	background-color: #eee;
	border-radius: 5px;
	padding: 0.3rem;
	margin-bottom: 1rem;
}
.NumberKeypad input[type="number"] {
	border-radius: 5px;
	border: none;
	width: 100%;
	padding: 10px;
	font-size: 3rem;
	text-align: center;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

.NumberKeypad.status-idle input[type="number"] {
	background-color: #fff;
}
.NumberKeypad.status-submitting input[type="number"] {
	background-color: #ffe099;
}
.NumberKeypad.status-complete input[type="number"] {
	background-color: #a1ffb4;
}
.NumberKeypad.status-error input[type="number"] {
	background-color: #ff9999;
}

.number-buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.number-button {
	margin: 0.2rem;
	padding: 1rem 0.5rem;
	flex: 0 0 calc(33.3333% - (0.4rem * 2));
	font-size: 2rem;
}

.submit-assemble,
.submit-disassemble {
	padding: 2rem 1rem;
	margin-top: 0.3rem;
	width: 100%;
	background-color: var(--color-action-primary);
	font-size: 2rem;
}
.submit-disassemble {
	padding: 1rem;
	background-color: var(--color-action-negative);
	font-size: 1rem;
}
