html {
	--color-primary: #366a49;
	--color-primary-darker: #275136;
	--color-primary-c: #fff;
	--color-secondary: rgb(84, 137, 104);
	--color-secondary-alt: #52977d;
	--color-secondary-c: #fff;
	--color-accent: red;
	--color-accent-c: #fff;

	/* Text / Content */
	--color-content-background: #fff;
	--color-content-text: #000000;

	/* Background */
	--color-main-background: #ffffff;

	--color-action-primary: #4caf50;
	--color-action-primary-c: #fff;
	--color-action-secondary: #52977d;
	--color-action-secondary-lighter: #7db8a2;
	--color-action-secondary-alt: #008ecb;
	--color-action-secondary-c: #fff;
	--color-action-negative: #d63141;
	--color-action-negative-c: #fff;
	--color-action-disabled: #cccccc;
	--color-action-disabled-c: #666666;

	--color-collapsible-header: #dddddd;
	--color-collapsible-header-c: #2d2d2d;

	--color-border: #ccc;
	--color-border-lighter: #ebebeb;
	--color-button-border: #808080;

	--color-table-border: #ddd;
	--color-table-alt-row: #f2f2f2;
	--color-table-hover-row: #ddd;

	--color-negative: #d63141;
}
