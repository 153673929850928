.checkbox-wrapper .switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
  }

  .checkbox-wrapper .switch input {
    display:none;
  }

  .checkbox-wrapper .slider {
    background-color: var(--color-button-border);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }

  .checkbox-wrapper .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 26px;
  }

  .checkbox-wrapper input:checked + .slider {
    background-color: var(--color-action-secondary);
  }

  .checkbox-wrapper input:checked + .slider:before {
    transform: translateX(26px);
  }

  .checkbox-wrapper .slider.round {
    border-radius: 34px;
  }

  .checkbox-wrapper .slider.round:before {
    border-radius: 50%;
  }