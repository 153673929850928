#LoginPage {
	height: 100vh;
	display: flex;
	align-content: center;
	justify-content: center;
	text-align: center;
	background-color: var(--color-primary);
	color: var(--color-primary-c);
}
#login-page-content {
	width: 500px;
	max-width: 90%;
	height: auto;
	padding: 10%;
	align-self: center;
	background-color: #00000033;
	border-radius: 5px;
}
#LoginPage .logo img {
	height: 5rem;
}
#LoginForm {
	color: var(--color-content-text);
	max-width: 500px;
	margin: 50px 0;
}

#LoginForm form {
	background-color: #eee;
	padding: 1rem;
}

#LoginForm form label {
	text-align: left;
}

#LoginForm .ErrorMessage {
	margin-bottom: 1rem;
}
